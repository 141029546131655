<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Produk</label>
                    <Listbox ref="dproduct" v-model="filters.product_id" :options="dataDropdownProduct" :multiple="true" :filter="true" 
                            optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownProduct($event, 'filter')">
                        <template #option="slotProps">
                            <div>{{slotProps.option.label}}</div>
                        </template>
                    </Listbox>
                </div>
            </div>
        </div>
         <div class="p-fluid">
            <p><b>Produk</b> : {{this.convertMultiSelectProductString(filters.product_id)}}</p><br>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
        <Button :loading="loading" label="Clear Filter" icon="pi pi-times" class="p-button-danger mr-2 my-1" @click="clearFilter" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownProduct: false,

            // dataDropdown
            dataDropdownProduct: null,

            // filter
            filters: {
                product_id: null,
            },

            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
        }
    },
    created(){
        this.searchDropdownProduct('');
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        product(){ 
            return this.convertMultiSelectProduct(this.filters.product_id); 
        },
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        convertMultiSelectProduct(data) {
            if(data){
                return data.map((item) => {
                    return item.product_id;
                });
            }else{
                return [];
            }
        },
        convertMultiSelectProductString(data) {
            if(data){
                return data.map((item) => {
                    return item.label;
                }).join(', ');
            }else{
                return '-';
            }
        },
        // DROPDOWN
        searchDropdownProduct(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dproduct.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownProduct = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/product',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownProduct = res.data.data;
                        this.loadingDropdownProduct = false;
                    } else if (purpose == null) {
                        this.dataDropdownProduct = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
        clearFilter(){
            this.filters.product_id = null;
            this.period = [new Date(), new Date()];

            this.$emit('submit');
        }
    }
}
</script>

